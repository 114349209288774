import { getAuth } from '@services/identity.service';
import {
    getInvestorHasInvestments,
    getInvestorNSEBankDetails,
} from '@services/investments.service';
import { getAmoNudge, getBankDetails } from '@services/investors.service';
import { getNseEligibleBanks } from '@services/nse-rfqs.service';
import { isTradingAllowed } from '@services/trading.service';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const auth = getAuth();

const fetchIsTradingAllowed = async (type) => {
    let tradingAllowed = {
        isTradingAllowed: false,
        isHoliday: false,
        isAmoTradingAllowed: false,
        isRfqAllowed: false,
        isPostMarketHours: false,
        isPreMarketHours: false,
        message: '',
    };
    const response = await isTradingAllowed(type);
    if (response?.status) {
        tradingAllowed.isTradingAllowed = response.entity?.isTradingAllowed;
        tradingAllowed.isHoliday = response.entity?.isHoliday;
        tradingAllowed.isAmoTradingAllowed = response.entity?.isAmoTradingAllowed;
        tradingAllowed.isRfqAllowed = response.entity?.isRfqAllowed;
        tradingAllowed.isPostMarketHours = response.entity?.isPostMarketHours;
        tradingAllowed.isPreMarketHours = response.entity?.isPreMarketHours;
        tradingAllowed.message = '';
    } else {
        if (type) tradingAllowed.message = response?.message[0];
    }
    return tradingAllowed;
};

const fetchInvestorNSEBankDetails = async () => {
    const auth = getAuth();
    const response = await getInvestorNSEBankDetails(auth?.id);
    if (response?.status) {
        return response.entity;
    }
};

const fetchHasInvestment = async () => {
    const response = await getInvestorHasInvestments();
    if (response?.status) {
        return response.entity?.hasInvestment;
    }
};

export const getBeneficiaryDetails = async () => {
    const beneficiaryDetails = await fetchInvestorNSEBankDetails();
    const data = [
        {
            title: 'Beneficiary Name',
            value: beneficiaryDetails?.beneficiaryName
                ? beneficiaryDetails?.beneficiaryName
                : '-',
        },
        {
            title: 'Bank Name',
            value: beneficiaryDetails?.bankName
                ? beneficiaryDetails?.bankName
                : '-',
        },
        {
            title: 'IFSC Code',
            value: beneficiaryDetails?.bankIFSCCode
                ? beneficiaryDetails?.bankIFSCCode
                : '-',
        },
        {
            title: 'Account Number',
            value: beneficiaryDetails?.accountNumber
                ? beneficiaryDetails?.accountNumber
                : '-',
        },
        {
            title: 'Account Type',
            value: beneficiaryDetails?.accountType
                ? beneficiaryDetails?.accountType
                : 'Current',
        },
    ];
    useInvestmentStore.setState((state) => ({
        ...state,
        beneficiaryDetails: data,
    }));
    return data;
};
const fetchEligibleBanks = async () => {
    const response = await getNseEligibleBanks();

    if (response?.status) {
        return response.entity;
    }
};
const fetchRegisteredBanks = async (masked = true) => {
    const response = await getBankDetails(auth.id, masked);

    if (response?.status) {
        return response.entity;
    }
};

export const initializeStore = async () => {
    if (auth?.role !== 'Admin' && auth?.accountType) {
        useInvestmentStore.setState((state) => ({
            ...state,
            loading: true,
        }));
        const { isTradingAllowed: isTradingAllowedStatus, isAmoTradingAllowed, isRfqAllowed, isHoliday, isPostMarketHours, isPreMarketHours, message } =
            await fetchIsTradingAllowed();

        const beneficiaryDetails = await getBeneficiaryDetails();
        const hasInvestment = await fetchHasInvestment();
        const eligibleBanksResponse = await fetchEligibleBanks();
        const registeredBankAccountsResponse = await fetchRegisteredBanks();
        useInvestmentStore.setState((state) => ({
            ...state,
            isTradingAllowed: isTradingAllowedStatus,
            isAmoTradingAllowed,
            isHoliday,
            isRfqAllowed,
            isPostMarketHours,
            isPreMarketHours,
            investmentError: message,
            beneficiaryDetails: beneficiaryDetails,
            hasInvestment,
            loading: false,
            eligibleBanks: eligibleBanksResponse,
            registeredBanks: registeredBankAccountsResponse,
        }));
    }
};

const initialState = {
    loading: true,
    opportunityDetails: null,
    investment: null,
    investmentOverviewDetails: null,
    isEligibleToInvest: false,
    isTradingAllowed: false,
    isAmoTradingAllowed: false,
    isHoliday: false,
    isRfqAllowed: false,
    isPostMarketHours: false,
    isPreMarketHours: false,
    beneficiaryDetails: null,
    ModalComponent: null,
    showModal: false,
    investmentError: null,
    hasInvestment: null,
    eligibleBanks: [],
    registeredBanks: [],
    showAmoBanner: null,
    promisedAmoOrderCount: null,
    pendingAmoOrderCount: null,
    pendingOrderCount: null,
    marketOpenTime: null
};

const useInvestmentStore = create(
    persist(
        (set, get) => ({
            ...initialState,
            setInvestment: (investment) =>
                set((state) => ({
                    ...state,
                    investment,
                })),
            setInvestmentError: (investmentError) =>
                set((state) => ({
                    ...state,
                    investmentError,
                })),
            setOpportunityDetails: (opportunityDetails) =>
                set((state) => ({
                    ...state,
                    opportunityDetails,
                })),

            setShowModal: (showModal) =>
                set((state) => ({
                    ...state,
                    showModal,
                })),
            setModalComponent: (ModalComponent) =>
                set((state) => ({
                    ...state,
                    ModalComponent,
                })),
            setInvestmentOverviewDetails: (investmentOverviewDetails) =>
                set((state) => ({
                    ...state,
                    investmentOverviewDetails,
                })),
            setIsTradingAllowed: async (type) => {
                const { isTradingAllowed, isAmoTradingAllowed, isHoliday, isRfqAllowed, isPreMarketHours, isPostMarketHours, message} = await fetchIsTradingAllowed(type);
                set((state) => ({
                    ...state,
                    isTradingAllowed,
                    isAmoTradingAllowed,
                    isHoliday,
                    isRfqAllowed,
                    isPostMarketHours,
                    isPreMarketHours,
                    investmentError: message,
                }));
            },
            setHasInvestment: async () => {
                const hasInvestment = await fetchHasInvestment();
                set((state) => ({
                    ...state,
                    hasInvestment,
                }));
            },
            setAmoNudge: async (investorId) => {
                const amoResponse = await getAmoNudge(investorId);
                if (amoResponse?.status) {
                    set((state) => ({
                        ...state,
                        showAmoBanner: amoResponse?.entity?.showAmoBanner,
                        promisedAmoOrderCount: amoResponse?.entity?.promisedAmoOrderCount,
                        pendingAmoOrderCount: amoResponse?.entity?.pendingAmoOrderCount,
                        pendingOrderCount: amoResponse?.entity?.pendingOrderCount
                    }));
                }
            },
            setMarketOpenTime: async (time) => {
                set((state) => ({
                    ...state,
                    marketOpenTime: time
                }))
            },
            setLoading: (loading) =>
                set((state) => ({
                    ...state,
                    loading,
                })),

            reset: () => {
                localStorage.clear('investment');
                set(() => initialState);
            },
        }),
        {
            name: 'investment', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
            partialize: (state) => ({
                investment: state.investment,
                investmentOverviewDetails: state.investmentOverviewDetails,
                beneficiaryDetails: state.beneficiaryDetails,
                isTradingAllowed: state.isTradingAllowed,
                isAmoTradingAllowed: state.isAmoTradingAllowed,
                isHoliday: state.isHoliday,
                isRfqAllowed: state.isRfqAllowed,
                isPostMarketHours: state.isPostMarketHours,
                isPreMarketHours: state.isPreMarketHours,
                eligibleBanks: state.eligibleBanks,
                marketOpenTime: state.marketOpenTime
            }),
            onRehydrateStorage: async (state) => {
                return async (state, error) => {
                    if (error) {
                        console.error(
                            'an error happened during hydration',
                            error
                        );
                    } else {
                        console.warn('hydration finished');
                    }
                };
            },
        }
    )
);

export default useInvestmentStore;
