import { Link } from '@atoms/index';
import { toTitleCase } from '@helpers/miscelleanous';
import HamburgerMenuItem from '@molecules/menu/HamburgerMenuItem';
import { LazyImage } from '@ui/atoms';
import { useWindowSize } from '@uidotdev/usehooks';
import React from 'react'

export const WithoutAuthHeader = ({
    LoginButton,
    SignupButton,
    handleHeaderItemsClick,
    menuOpen,
    handleMenu,
    loginModal,
    signUpModal,
    menu,
    setMenuOpen,
}) => {
  const { height } = useWindowSize();
  return (
      <>
          <div className="flex items-center justify-between w-full">
              <Link href="/">
                  <LazyImage
                      src={'/images/logo.svg'}
                      className="w-[120px] h-7 relative"
                      alt="logo"
                  />
              </Link>
          </div>
          <div className="flex items-center gap-x-6 md:gap-x-8">
              <>
                  {!menuOpen ? (
                      <div className="flex items-center md:hidden gap-x-2">
                          <LoginButton
                              onClick={() => {
                                  loginModal();
                              }}
                              className="cursor-pointer text-primary-500 px-6 py-[7px] rounded-full border-solid border-1 font-medium border-primary-500FF border-primary-500"
                          />
                      </div>
                  ) : null}

                  <div className="items-center hidden md:flex gap-x-2">
                      <LoginButton
                          onClick={() => {
                              loginModal();
                          }}
                      />
                      <SignupButton
                          onClick={() => {
                              signUpModal();
                          }}
                      />
                  </div>
              </>
              <button
                  onClick={handleMenu}
                  className="outline-none mobile-menu-button"
              >
                  <LazyImage
                      src={
                          !menuOpen
                              ? '/images/homepage/bar.svg'
                              : '/images/close-hamburger.svg'
                      }
                      alt={!menuOpen ? 'bar' : 'close'}
                      className="relative w-5 h-4"
                  />
              </button>
          </div>
          <div
              className={`${
                  menuOpen
                      ? 'mobile-menu-visible block'
                      : 'mobile-menu-hidden hidden'
              } fixed top-16 right-0 left-0 md:left-auto z-50 mobile-menu min-width-full md:w-[350px]`}
          >
              <div
                  className="relative flex flex-col justify-between w-full pb-20 bg-basicWhite md:w-full backdrop-blur-lg mobile-menu-content"
                  style={{ height: height }}
              >
                  <ul className="px-5 overflow-y-auto">
                      {menu
                          ?.filter((each) => each !== null)
                          ?.filter((each) => {
                              if (each?.showWhenNotLoggedIn) {
                                  return each;
                              }
                          })
                          .map((item) => {
                              {
                                  return (
                                      <li key={item.name}>
                                          <HamburgerMenuItem
                                              menuItem={item}
                                              closeMenu={() =>
                                                  setMenuOpen(false)
                                              }
                                              handleHeaderItemsClick={
                                                  handleHeaderItemsClick
                                              }
                                          />
                                      </li>
                                  );
                              }
                          })}
                  </ul>
                  <div className="flex items-center justify-center gap-4 py-6 bg-basicWhite md:hidden">
                      <LoginButton
                          onClick={() => {
                              loginModal();
                          }}
                      />
                      <SignupButton
                          onClick={() => {
                              signUpModal();
                          }}
                      />
                  </div>
              </div>
          </div>
      </>
  );
}
