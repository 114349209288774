import {hasWindow} from '@services/http.service';
import * as CsrHttpService from "@http/csr-http.service";
import * as SsrHttpService from "@http/ssr-http.service";
import { IS_TRADING_ALLOWED_URL } from './urls/tradingStatus';

export const isTradingAllowed = (type = '', ctx=null) => {
    const url = IS_TRADING_ALLOWED_URL(type);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url,ctx);
};

