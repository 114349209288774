import AuthContext from '@context/auth';
import { toTitleCase } from '@helpers/miscelleanous';
import useEventListener from '@hooks/useEventListner';
import { getAuth } from '@services/identity.service';
import useAuthStore from '@store/useAuthStore';
import { useClickAway } from '@uidotdev/usehooks';
import { useContext, useState } from 'react';
import { WithoutAuthHeader } from './WithoutAuthHeader.js';
import WithAuthHeader from './WithAuthHeader/index.js';

const MobileNavbar = ({ LoginButton, SignupButton, homePage, handleHeaderItemsClick }) => {
    const auth = getAuth();
    const { isLogged } = useAuthStore();
    const name = toTitleCase(auth?.name);

    const {
        loginModal,
        signUpModal,
        logout,
        menuItems: menu,
        rmDetails,
        investor,
        showRMDetails,
    } = useContext(AuthContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const ref = useClickAway(() => {
        setMenuOpen(false);
    });

    useEventListener('scroll', () => {
        setMenuOpen(false);
    });

    return (
        <div
            className="flex items-center justify-between w-full xl:hidden"
            ref={ref}
        >
            <div className="flex items-center justify-between w-full px-5 md:px-8">
                {isLogged ? (
                    <WithAuthHeader
                        {...{
                            name,
                            menuOpen,
                            handleMenu,
                            rmDetails,
                            showRMDetails,
                            menu,
                            isStaticPage: homePage,
                            logout,
                            kycStatus: investor?.kycStatus,
                        }}
                    />
                ) : (
                    <WithoutAuthHeader
                        {...{
                            LoginButton,
                            SignupButton,
                            handleHeaderItemsClick,
                            menuOpen,
                            handleMenu,
                            loginModal,
                            signUpModal,
                            menu,
                            setMenuOpen,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default MobileNavbar;
